import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="footer">
        <a href="https://github.com/maykim51/scc-hotplace">
          <span>https://github.com/maykim51/scc-hotplace</span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
